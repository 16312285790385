<template>
  <div class="container mescroll-touch">
    <div class="box">
      <div class="title">我的作品</div>
      <div class="listBox" id="dataList">
          <not-data v-if="showNotData"></not-data>
          <waterfall v-else :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
            <template>
              <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
                <img v-if="item.Type == 1" class="item_cover" src="@/assets/images/cover.png" />
                <img v-else class="item_cover" :src="item.Url" />
                <div class="item_title">{{item.Title}}</div>
              </div>
            </template>
          </waterfall>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotData from "../../components/noData.vue"
export default {
  name: 'MyWorks', 
  data() {
    return {
      list: [],
      showNotData: false
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  components: {
    NotData
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios.get('/Api/api/Web/Works/GetWorks?cuid=' + this.userInfo.Id).then(res => {
        this.list = res.Data;
        if(this.list.length <= 0) {
          this.showNotData = true;
        }
      })
    },
    toContentPage(id, type) {
      this.$router.push({
        path: 'worksDetail',
        query: {
          id: id,
          type: type
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #F9F9F9;
  padding-top: 20px;
  box-sizing: border-box;
  .box {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 10px 10px 0 0;
    .title {
      position: relative;
      font-size: 15px;
      color: #CA001B;
      padding: 8px 20px;
      box-sizing: border-box;
    }
    .title::after {
      position: absolute;
      left: 20px;
      bottom: 0px;
      content: "";
      width: 60px;
      height: 2px;
      background-color: #CA001B;
    }
    .listBox {
      flex: 1;
      background-color: #F9F9F9;
      border-radius: 12px 12px 0 0;
      padding: 15px 12px;
    }
    .list {
      background-color: #FFFFFF;
      border-radius: 4px;
      padding: 8px;
      margin-bottom: 10px;
      .item_cover {
        display: block;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 8px;
      }
      .item_title {
        font-size: 12px;
        color: #333333;
        height: 20px;
        line-height: 20px;
        border-radius: 20px;
        background-color: #F5F5F5;
        text-align: center;
        padding: 0 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
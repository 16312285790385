<template>
  <div class="noData">
    <img class="icon" src="@/assets/images/none_comm_png@2x.png" />
    <p class="tips">{{tips}}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    tips: {
      type: String,
      default:"暂无数据"
    }
  }
}
</script>

<style lang="scss" scoped>
.noData {
  .icon {
    display: block;
    width: 180px;
    margin: 20px auto;
  }
  .tips {
    text-align: center;
    font-size: 12px;
    color: #CCCCCC;
  }
}
</style>